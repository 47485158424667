export const HOCOSCO_TYPE = 'hocosco';
export const METASTORE_TYPE = 'metastore';
//message
//login-message
export const MESSAGE_LOGIN_SUCCESS = 'ログインに成功しました';
export const MESSAGE_ERROR_LOGIN =
    'メールアドレスまたはパスワードが間違っています。';
export const MESSAGE_ERROR_LOGIN_SOCIAL_MEDIA =
    'アカウントはシステム上に存在しません。正しく入力したことをご確認ください。';
// register-message
export const MESSAGE_REGISTER_SUCCESS = '登録が成功しました';
export const MESSAGE_ERROR_REGISTER_SAME_NAME = '登録済みのユーザIDです。';
export const MESSAGE_ERROR_REGISTER_SAME_EMAIL =
    '登録済みのメールアドレスです。';
export const MESSAGE_ERROR_REGISTER_SAME_NAME_AND_EMAIL =
    '登録済みのユーザID & メールアドレスです。';
//forgot password -message
export const MESSAGE_SENDMAIL_SUCCESS =
    'メールを送信しました。メールを確認してください。';
export const MESSAGE_SENDMAIL_FAILED = '未登録のメールアドレスです。';
//reset password-message
export const MESSAGE_RESET_PASSWORD_SUCCESS =
    '新しいパスワードが設定されました。';
export const MESSAGE_ERROR_PASSWORD_AND_CONFIRM_PASSWORD_NOT_MATCH =
    'パスワードとパスワード（確認用）が異なっています。';
export const MESSAGE_ERROR_RESET_PASSWORD_SERVER =
    'エラーが発生しました。もう一度やり直してください';
// message-validate
//email
export const MESSAGE_VALIDATE_EMAIL_TYPE =
    '正しいメールアドレスではありません。';
export const MESSAGE_VALIDATE_EMAIL_REQUIRED = 'メールアドレスは必須です。';
// password
export const MESSAGE_VALIDATE_PASSWORD_TYPE =
    'パスワードは半角英数特殊文字：8～20文字で入力してください。';
export const MESSAGE_VALIDATE_PASSWORD_REQUIRED = 'パスワードは必須です。';
export const MESSAGE_VALIDATE_CONFIRM_PASSWORD_REQUIRED =
    'パスワードの再入力は必須です。';
//name
export const MESSAGE_VALIDATE_NAME_TYPE =
    'ユーザIDは半角英数字：4～20文字で入力してください。';
export const MESSAGE_VALIDATE_NAME_REQUIRED = 'ユーザIDは必須です。';
// validate password
export const MESSAGE_VALIDATE_LENGTH_PASSWORD = '8～20文字で入力してください。';
export const MESSAGE_VALIDATE_TYPE1_PASSWORD =
    '半角の小文字と大文字を1文字以上含めてください。';
export const MESSAGE_VALIDATE_TYPE2_PASSWORD =
    '数字と特殊文字（!@#$%^&*）を1文字以上含めてください。';
export const MESSAGE_VALIDATE_TYPE3_PASSWORD =
    '半角英数特殊文字を入力してください。';
