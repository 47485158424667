/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Form } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { resetPassword } from '../../../services/apiService';
import { toast } from '../../../utils/notification';
import {
    MESSAGE_ERROR_PASSWORD_AND_CONFIRM_PASSWORD_NOT_MATCH,
    MESSAGE_ERROR_RESET_PASSWORD_SERVER,
    MESSAGE_RESET_PASSWORD_SUCCESS,
    MESSAGE_VALIDATE_CONFIRM_PASSWORD_REQUIRED,
    MESSAGE_VALIDATE_LENGTH_PASSWORD,
    MESSAGE_VALIDATE_PASSWORD_REQUIRED,
    MESSAGE_VALIDATE_TYPE1_PASSWORD,
    MESSAGE_VALIDATE_TYPE2_PASSWORD,
    MESSAGE_VALIDATE_TYPE3_PASSWORD,
} from '../../../components/common/constant';
import InputPassword from '../common/InputPassword';
import { backToTopScroll } from '../common/FunctionCommon';

const ResetPassWord = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const email = localStorage.getItem('email');
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const [errorMessage, setErrorMessage] = useState('');
    const handleSubmitResetPw = async () => {
        if (form.getFieldValue('password') !== form.getFieldValue('confirmPassword')) {
            setErrorMessage(MESSAGE_ERROR_PASSWORD_AND_CONFIRM_PASSWORD_NOT_MATCH);
        } else {
            setErrorMessage('');
            setLoading(true);
            const requestData = {
                email: email,
                reset_password_token: urlParams.get('reset_password_token'),
                password: form.getFieldValue('password'),
                password_confirmation: form.getFieldValue('confirmPassword'),
                grant_type: 'update_password',
            };
            const response: any = await resetPassword(requestData);
            setLoading(false);
            if (response?.statusCode === 200) {
                toast('success', MESSAGE_RESET_PASSWORD_SUCCESS);
            } else {
                toast('error', MESSAGE_ERROR_RESET_PASSWORD_SERVER);
            }
            backToTopScroll();
        }
    };
    return (
        <div className="layout-container">
            <div className="flex register-container !h-[100vh]">
                <div className="w-[50%] flex justify-center items-center max-[840px]:hidden">
                    <img src="/image/loginImg.svg" alt="banner" className="object-cover flex justify-center items-center" />
                </div>
                <div className="w-[50%] max-[840px]:w-full flex items-center justify-center bg-[#fafafa]">
                    <div className="w-[318px]">
                        <div className="font-medium text-[18px] leading-[20px] text-center text-[#222]">パスワードの再設定</div>
                        <div className="mt-[24px] mb-[32px] text-color">
                            <p>パスワードには、小文字、大文字、数字、特殊文</p>
                            <p>字（!@#$%^&*）が含まれます。</p>
                        </div>
                        <Form
                            onValuesChange={() => setErrorMessage('')}
                            onFinishFailed={() => setErrorMessage('')}
                            onFinish={handleSubmitResetPw}
                            className=""
                            name="trigger"
                            style={{ maxWidth: 600 }}
                            layout="vertical"
                            autoComplete="off"
                            form={form}>
                            <Form.Item
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value) {
                                                if ((value && value.length < 8) || (value && value.length > 20)) {
                                                    return Promise.reject(new Error(MESSAGE_VALIDATE_LENGTH_PASSWORD));
                                                } else if (!/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value)) {
                                                    return Promise.reject(new Error(MESSAGE_VALIDATE_TYPE1_PASSWORD));
                                                } else if (!/^(?=.*[0-9])(?=.*[(!@#$%^&*)]).*$/.test(value)) {
                                                    return Promise.reject(new Error(MESSAGE_VALIDATE_TYPE2_PASSWORD));
                                                } else if (!/^[a-zA-Z0-9(!@#$%^&*)]+$/.test(value)) {
                                                    return Promise.reject(new Error(MESSAGE_VALIDATE_TYPE3_PASSWORD));
                                                }
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                    {
                                        required: true,
                                        message: MESSAGE_VALIDATE_PASSWORD_REQUIRED,
                                    },
                                ]}
                                htmlFor="password"
                                className="!mb-[30px]"
                                label="新しいパスワード（半角英数特殊文字：8～20文字）"
                                name="password">
                                <InputPassword name="password" idInput="password" />
                            </Form.Item>
                            <Form.Item
                                htmlFor="confirmPassword"
                                className="!mb-[40px]"
                                label="パスワードの再入力（確認用）"
                                name="confirmPassword"
                                dependencies={['password']}
                                rules={[
                                    {
                                        required: true,
                                        message: MESSAGE_VALIDATE_CONFIRM_PASSWORD_REQUIRED,
                                    },
                                ]}>
                                <InputPassword name="confirmPassword" idInput="confirmPassword" />
                            </Form.Item>

                            <Form.Item className="mb-[40px]" shouldUpdate>
                                {() => (
                                    <>
                                        {errorMessage && (
                                            <div className="text-[#F00] text-center text-[12px] font-medium mb-[8px]">{errorMessage}</div>
                                        )}
                                        <Button
                                            disabled={
                                                form.getFieldsError().filter(({ errors }) => errors.length).length > 0 || !!errorMessage
                                            }
                                            type="primary"
                                            loading={loading}
                                            htmlType="submit"
                                            className="h-[48px] !text-[white] w-full rounded-[5px] !bg-[#E25252]">
                                            次へ
                                        </Button>
                                    </>
                                )}
                            </Form.Item>
                        </Form>

                        <div
                            onClick={() => navigate('/')}
                            className="cursor-pointer text-center mt-[32px] text-[14px] text-[#222222] font-medium underline">
                            ログイン画面に戻る
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default ResetPassWord;
