import { Button } from 'antd';
import React from 'react';

const MyCustomGoogleButton = ({ login }: { login: () => void }) => {
    return (
        <Button className="flex justify-center items-center gap-[5px] !bg-[unset] !border-0 !p-0" onClick={() => login()}>
            {' '}
            <img src="/image/icGoogle.svg" />
        </Button>
    );
};
export default MyCustomGoogleButton;
