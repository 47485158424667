import { lazy } from 'react';
import * as URL from './constantRouter';
import AuthLogin from '../layout/UnAuthentication/Login/AuthLogin';
import Register from '../layout/UnAuthentication/Register';
import ForgotPassWord from '../layout/UnAuthentication/ForgotPassword';
import ResetPassWord from '../layout/UnAuthentication/ResetPassword';
const Login = lazy(() => import('../layout/UnAuthentication/Login'));

export const publicRoutes = [
    { path: '/', component: Login },
    { path: URL.URL_LOGIN, component: Login },
    { path: URL.URL_LOGIN_AUTH, component: AuthLogin },
    { path: URL.URL_REGISTER, component: Register },
    { path: URL.URL_FORGOT_PW, component: ForgotPassWord },
    { path: URL.URL_FORGOT_PW, component: ForgotPassWord },
    { path: URL.URL_RESET_PW, component: ResetPassWord },
];

export const privateSettingRoutes = [];
